import * as React from 'react'
import { useState, useEffect } from 'react'

const CountKwhPricePerMin = () => {

    const [ pricePerMinute, setPricePerMinute ] = useState(null)
    const [ priceKw, setPriceKw ] = useState(null)
    const [ pricePerKwh, setPricePerKwh ] = useState('?')

    function priceMinChange(evt) {
        setPricePerMinute(evt.target.value)
        calculatePrice(evt.target.value, priceKw)
    }
    function priceKwChange(evt) {
        setPriceKw(evt.target.value)
        calculatePrice(pricePerMinute, evt.target.value)
    }

    function calculatePrice(perMinute, kW) {
        if(perMinute && kW) {
            var tmpPrice = parseFloat(perMinute)*60/parseFloat(kW);
            setPricePerKwh('~ '+parseFloat(tmpPrice))
        }
    }

    return (<>
        <div style={{marginTop: '10px', marginLeft: '20px'}}>
            <h4>Räkna ut själv:</h4>
            <input type="number" step="0.1" onChange={priceMinChange} style={{textAlign: 'center', borderRadius: '9px', border: '1px solid gray', padding: '5px 10px', width: '50px', margin: '5px 0px'}} /> kr/min <br />
            <input type="number" step="0.1" onChange={priceKwChange} style={{textAlign: 'center', borderRadius: '9px', border: '1px solid gray', padding: '5px 10px', width: '50px', margin: '5px 0px'}} /> kW <br />

            <input value={pricePerKwh} disabled="disabled" style={{textAlign: 'right', borderRadius: '9px', border: '1px solid gray', padding: '5px 10px', width: '50px', margin: '5px 0px'}} /> kr/kWh
        </div>
    </>)
}

export default CountKwhPricePerMin