import * as React from 'react'
import Layout from '/src/components/layout'
import CountKwhPricePerMin from '/src/components/countKwhPricePerMin'

const VerktygPage = () => {

    return (
        <Layout pageTitle="Verktyg">

            <h2>Kronor/kWh vid pris/min</h2>
            <CountKwhPricePerMin />
            
        </Layout>
    )
}

export default VerktygPage